<template>
  <div class="w-100">
    <!-- <v-row justify="center">
      <v-col
        cols="12"
        xl="10"
        class="gestion-du-club__form nom-correspondant__form"
      > -->
    <!-- <div class="form-obligatory pb-4">
          Cette rubrique est obligatoire
        </div> -->
    <v-row justify="center">
      <v-col cols="6">
        <div class="mb-4 font-weight-black">Correspondant:</div>
        <v-card max-width="344" outlined>
          <v-list-item v-if="currentUser" three-line>
            <!-- <v-avatar color="red" size="36">
                  <img v-if="userAvatar" :src="userAvatar" />
                  <span v-else class="white--text font-weight">{{
                    getInitialUser
                  }}</span>
                </v-avatar> -->

            <Avatar
              :context="{ ID_Utilisateur: currentUser.ID_Utilisateur }"
              :key="avatarKey"
            />

            <v-list-item-content class="ml-4">
              <div>{{ userName }}</div>
              <div v-if="userFonction">{{ userFonction }}</div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else>
            <v-list-item-content class="">
              <div>Aucun utilisateur sélectionné</div>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <div class="mt-4">
          <v-btn
            :disabled="!hasCodeFederal"
            @click="userModalIsOpen = true"
            class="btn btn-primary"
            >Sélectionner un utilisateur</v-btn
          >
        </div>

        <ModalUsers
          :isOpen="userModalIsOpen"
          @onClose="userModalIsOpen = false"
          :context="{
            ID_Structure: currentStructureId,
            ID_Saison: currentSaison.id,
            onlyCurrentStructure: true,
          }"
          @onUserSelected="userSelected"
        ></ModalUsers>
      </v-col>

      <v-col cols="6">
        <div class="mb-4 font-weight-black">Adresse de correspondance:</div>
        <v-card max-width="344" outlined>
          <v-list-item v-if="currentAdresse" three-line>
            <v-list-item-content>
              <div class="mb-4 font-weight-medium text-uppercase">
                {{ currentAdresse.NomAdresse }}
              </div>
              <div>{{ currentAdresse.Adresse1 }}</div>
              <div>{{ currentAdresse.Adresse2 }}</div>
              <div>{{ currentAdresse.CodePostal }}</div>
              <div>{{ currentAdresse.Ville }}</div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else>
            <v-list-item-content class="">
              <div>
                Aucune adresse de correspondance assignée
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <div class="mt-4">
          <v-btn
            :disabled="!hasCodeFederal"
            @click="adresseModalIsOpen = true"
            class="btn btn-primary"
            >Sélectionner une adresse</v-btn
          >
        </div>

        <ModalAdresse
          v-if="!loading && adresseModalIsOpen"
          :isOpen="adresseModalIsOpen"
          @onClose="adresseModalIsOpen = false"
          @onSelected="adresseSelected"
          @onUpdated="adressUpdated"
          ref="adresseModal"
          :item="currentAdresse"
          selection
          :context="{ ID_Structure: currentStructureId }"
        ></ModalAdresse>
      </v-col>
    </v-row>
    <!-- </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { success_notification } from "@/plugins/notifications";
import Avatar from "@/components/Common/Common__Avatar.vue";
// import {isEmpty} from "../../plugins/utils";

export default {
  name: "NomCorrespondant",

  components: {
    ModalUsers: () =>
      import(
        /* webpackChunkName: "modal_users" */ "@/components/utils/modals/users.vue"
      ),
    ModalAdresse: () =>
      import(
        /* webpackChunkName: "modal_adresses" */ "@/components/utils/modals/adresses.vue"
      ),
    Avatar,
  },

  data: () => ({
    loading: true,
    userModalIsOpen: false,
    adresseModalIsOpen: false,
    currentUser: null,
    currentAdresse: null,
    avatarKey: 0,
  }),

  async created() {
    await this.setData();
    this.loading = false;
    return;
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "hasCodeFederal",
    ]),
    ...mapGetters("basics", ["getDocumentTypeAv²atarUser"]),
    ...mapGetters("saisons", ["currentSaison"]),

    structureAdresse() {
      if (this.loading) return [];
      return this.currentStructure.ADR_Adresse[0];
    },

    userName() {
      return (
        this.currentUser.UTI_Utilisateur.CT_Nom +
        " " +
        this.currentUser.UTI_Utilisateur.CT_Prenom
      );
    },

    userFonction() {
      if (this.currentUser.FonctionUtilisateur) {
        return (
          this.currentUser.FonctionUtilisateur.NomFonction +
          " " +
          this.currentUser.DescriptionFonction
        );
      }
      return "";
    },

    userAvatar() {
      let avatar = this.currentUser.UTI_Utilisateur.DOC_Documents.find(
        (doc) => doc.ID_Type_Document === this.getDocumentTypeAvatarUser.id
      );

      if (avatar) {
        return avatar.CheminDocument;
      }
      return null;
    },

    getInitialUser() {
      return (
        this.currentUser.UTI_Utilisateur.CT_Nom.substring(0, 1).toUpperCase() +
        this.currentUser.UTI_Utilisateur.CT_Prenom.substring(0, 1).toUpperCase()
      );
    },
  },

  methods: {
    ...mapActions("structure", [
      "updateStructureNomCorrespondant",
      "updateStructureAdresseCorrespondant",
      "getStructureAdresses",
    ]),
    ...mapActions("structure", ["getStructureUtilisateur"]),

    async userSelected(user) {
      this.currentUser = user;
      this.userModalIsOpen = false;

      try {
        await this.updateStructureNomCorrespondant(user.UTI_Utilisateur);
        success_notification("Modifications enregistré");
        await this.setData();
      } catch (e) {
        console.log(e);
      }
    },

    async setData() {
      this.adresses = await this.getStructureAdresses({
        ID_Structure: this.currentStructureId,
      });

      if (this.currentStructure.STR_Structure.ID_NomCorrespondant) {
        this.currentUser = await this.getStructureUtilisateur({
          ID_Utilisateur: this.currentStructure.STR_Structure
            .ID_NomCorrespondant,
          ID_Structure: this.currentStructureId,
        });
        this.avatarKey += 1;
      }

      if (this.currentStructure.STR_Structure.ID_AdresseCorrespondance) {
        this.currentAdresse = this.adresses.find(
          (adresse) =>
            adresse.id ==
            this.currentStructure.STR_Structure.ID_AdresseCorrespondance
        );
      }

      return;
    },

    async adresseSelected(adresse) {
      this.currentAdresse = adresse;
      this.adresseModalIsOpen = false;
      //this.adresse.ID_Structure = this.currentStructure.ID_Structure
      try {
        await this.updateStructureAdresseCorrespondant(adresse);
        success_notification("Modifications enregistré");
      } catch (e) {
        console.log(e);
      }
    },

    async adressUpdated(item) {
      this.adresseSelected = item;
      this.adresses = await this.getStructureAdresses({
        ID_Structure: this.currentStructureId,
      });
    },
  },
};
</script>

<style lang="scss"></style>
